<template>
  <div class="common_header"
    @mouseenter="() => { inter = true }"
    @mouseleave="() => { inter = false; chooseData = null }">
    <div class="container clearfix">
      <a class="pull-left" href="/home"><img src="../../assets/img/logo.png" /></a>
      <div class="pull-left menu">
        <a 
          v-for="item in menuList" 
          :href="item.url" 
          :key="item.path" 
          @mouseenter="() => { chooseData = inter ? item.list ? item : null : null }"
          :class="{ 'active': item.path == $route.meta.path }">
          {{ item.title }}
        </a>
      </div>
      <div v-if="!logined" class="pull-right">
        <el-link @click="visible = true || ($store.state.open ? true : false)">登录</el-link>
        <span>/</span>
        <el-link href="/register">注册</el-link>
      </div>
      <div v-if="logined" class="pull-right">
        <el-link href="/personCenter">个人中心</el-link>
        <span>/</span>
        <el-link @click="loginOut">安全退出</el-link>
      </div>
    </div>
    <LoginModal :visible="visible" @close="closeClick"></LoginModal>
    <TypePanel v-if="chooseData && chooseData.panel" :listData="chooseData && chooseData.list" :type="chooseData && chooseData.panel"></TypePanel>
  </div>
</template>

<script>
import LoginModal from './LoginModal'
import TypePanel from './TypePanel'
export default {
  components: {
    LoginModal,
    TypePanel
  },
  data() {
    return {
      visible: false,
      menuList: [
        { title: '首页', path: 'home', url: '/home' },
        { title: '最新资讯', path: 'notificationInformation', url: '/notificationInformation', panel: 1, list: [
          { icon: 'notice', title: '全部资讯', url: '/notificationInformation?pager=1', type: 'all' },
          { icon: 'manual', title: '通知公告', url: '/notificationInformation?pager=1', type: '0' },
          { icon: 'explore', title: '行业动态', url: '/notificationInformation?pager=1', type: '1' },
          { icon: 'book-mark', title: '培训信息', url: '/notificationInformation?pager=1', type: '3' },
          { icon: 'recruit', title: '招贤纳士', url: '/notificationInformation?pager=1', type: '2' },
        ] },
        { title: '知识库', path: 'knowledgeBase', url: '/knowledgeBase/POLICY', panel: 2, list: [
          { icon: 'rule', title: '政策法规', url: '/knowledgeBase/POLICY', tip: '精准把握政策导向' },
          { icon: 'standard', title: '标准', url: '/knowledgeBase/STANDARD', tip: '面向标准化专业人员的标准库' },
          { icon: 'book', title: '书籍', url: '/knowledgeBase/BOOK', tip: '专业标准化书籍一网打尽' },
          { icon: 'presentation', title: '课程', url: '/knowledgeBase/COURSE', tip: '名师录播课程，易学好懂' },
        ]  },
        { title: '测评', path: 'testReviews', url: '/testReviews' },
        { title: '专项服务', path: 'specializedServices', url: '/specializedServices' },
      ],
      logined: false,
      chooseData: null,
      inter: false
    }
  },
  watch: {
    '$store.state.open': {
      handler(n) {
        if(n) this.visible = true;
      }
    },
    '$store.state.loginIn': {
      handler(n) {
        if(n) this.logined = true;
        else this.logined = false
      }
    },
  },
  mounted() {
    this.showPage()
  },
  methods: {
    showPage() {
      this.$API.ForntUserDetail().then(res => {
        if(res.data.code == 200) {
          this.loginData = res.data.data;
          this.$store.commit('login_type', 1)
          this.$store.commit('userInfo_type', res.data.data)
        }else if(res.data.code == 891) {
          if(this.$route.meta.needLogin) {
            this.visible = true
          }
          this.$store.commit('login_type', 0)
          this.$store.commit('userInfo_type', null)
        }
      })
    },
    loginOut() {
      this.$API.OutLogin().then(res => {
        this.login = 0;
        this.$store.commit('login_type', 0)
        if(this.$route.name == 'personCenter') this.$router.push('/')
      })
    },
    closeClick() {
      this.$store.commit('open_type', 0)
      this.visible = false;
    }
  }
}
</script>

<style lang='scss'>
  .common_header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 64px;
    background: #ffffff;
    z-index: 1001;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05); 
 
    .container {
      width: 1200px;
      padding: 0 10px;
      margin: 0 auto;

      & > a {
        display: block;
        flex-shrink: 0;
        width: 293px;
        height: 64px;

        & > img {
          width: 293px;
          height: 64px;
        }
      }

      .menu {
        display: flex;
        margin-left: 20px;

        & > a {
          font-size: 16px;
          color: #606266;
          margin-right: 30px;
          padding: 20px 0 19px;
          border-bottom: 3px #fff solid;
          cursor: pointer;
          display: block;
          text-decoration: none;
          line-height: 22px;
          position: relative;

          &:hover {
            color: #0072ef;   
            border-bottom: 3px #0072EF solid;
            font-weight: 600;
          }
        }

        & > a.active {
          color: #0072ef;   
          border-bottom: 3px #0072EF solid;
          font-weight: 600;
        }
      }

      .pull-right {
        padding: 22px 10px;
        line-height: 20px;

        & > span {
          font-size: 14px;
          color: #c0c4cc;
          margin: 0 10px;
        }

        .el-link:hover {
          color: #0072EF;
        }
      }
    }
  }
</style>