<template>
  <div class="type_panel" @mouseleave="leaveEvent">
    <div class="content">
      <div v-for="item in listData" :key="item.icon" 
        :style="{ 
          'width': type == 1 ? '220px' : '280px',
          'display':  type == 1 ? 'auto' : 'flex',
        }" class="pointer"
        @click="eventClick(item.url, item.type)">
        <svg-icon :icon-class="item.icon"></svg-icon>
        <div v-if="type == 2">
          <h3>{{ item.title }}</h3>
          <p>{{ item.tip }}</p>
        </div>
        <h3 v-else>{{ item.title }}</h3>
      </div>
    </div>
  </div>  
</template>

<script>
export default {
  props: {
    listData: {
      type: Object,
      required: true
    },
    type: {
      type: Number,
      required: true
    }
  },
  methods: {
    eventClick(url, type) {
      if(type) {
        this.$store.commit('news_type', type)
      }
      this.$router.push(url)
    }
  }
}
</script>

<style lang='scss'>
  .type_panel {
    background: #ffffff;
    padding: 40px 0;
    width: 100vw;
    position: absolute;
    z-index: 1000;
    top: 64px;
    border-top: 1px solid #eee;


    .content {
      width: 1180px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-items: center;

      & > div {
        margin-right: 20px;
        height: 100px;
        background: #f5f9fb;
        border-radius: 4px;
        text-align: center;
        padding: 20px 25px;
        align-items: center;

        &:hover {
          background: #0072EF;
          color: #fff;

          .svg-icon {
            color: #fff;
          }

          & > h3,
          & > div > h3,
          & > div > p {
            color: #fff
          }
        }

        &:last-child {
          margin-right: 0;
        }

        .svg-icon {
          width: 36px;
          height: 36px;
          color: #5078FE;
          margin-bottom: 4px;
        }

        & > h3 {
          font-size: 16px;
          color: #303133;
        }

        & > div {
          text-align: left;
          margin-left: 15px;

          & > h3 {
            font-size: 16px;
            color: #303133;
          }

          & > p {
            margin-top: 5px;
            font-size: 14px;
            color: #909399;
          }
        } 
      }
    }

    
  }
</style>