<template>
  <div class="common_bottom">
    <div class="bottom_one">
      <div class="container">
        <span>友情链接:</span>
        <a href="https://std.samr.gov.cn/" target="_blank">全国标准信息公共服务平台</a>
        <el-divider direction="vertical" />
        <a href="http://www.ttbz.org.cn/" target="_blank">全国团体标准信息平台</a>
        <el-divider direction="vertical" />
        <a href="https://www.bz360.org.cn/ESA_Query_STD/Default.aspx" target="_blank">广东省标准信息公共服务平台</a>
        <el-divider direction="vertical" />
        <a href="http://amr.gd.gov.cn/" target="_blank">广东省市场监督管理局</a>
        <el-divider direction="vertical" />
        <a href="https://www.gdis.org.cn/" target="_blank">广东省标准化研究院</a>
        <el-divider direction="vertical" />
        <a href="https://www.standard.jinzhicloud.com/" target="_blank">标准云</a>
      </div>
    </div>
    <div class="bottom_two clearfix">
      <div class="container">
        <span class="pull-left">Copyright © 2022 <a href="https://www.jinzhicloud.com" target="_blank">广东金质信息技术有限公司</a> All rights reserved. <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021084390号</a></span>
        <div class="pull-right">
          <a href="/userAgreement">用户协议</a>
          <a href="/specializedServices">联系我们</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang='scss'>
  .common_bottom {
    height: 64px;
    background: #ffffff;
    border-top: 1px solid #EBEBEB;

    .container {
      width: 1200px;
      margin: 0 auto;
      padding: 0 10px;

      .el-divider--vertical {
        height: 16px;
        margin: 0 15px;
      }
    }

    .bottom_one {
      padding: 15px 0;

      .container {
        display: flex;
        align-items: center;
        color: #606266;
        height: 20px;
        font-size: 14px;

        span {
          margin-right: 15px;
        }

        a {
          color: #606266;
          text-decoration: none;

          &:hover {
            color: #0072EF;
          }
        }
      }
    }

    .bottom_two {
      padding: 22px 0;
      font-size: 14px;
      color: #909399;
      background: #303133;
      line-height: 20px;

      a {
        text-decoration: none;
        color: #909399;

        &:hover {
          color: #0072EF;
        }
      }

      .pull-right {
        a { 
          margin-left: 20px;
        }
      }
    }
  }
</style>