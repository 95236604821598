<template>
  <el-dialog 
    v-model="visible"
    :width="390"
    title=""
    custom-class="login_modal"
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="$emit('close')">
      <h3>登录</h3>
      <p>还没有账号?<el-link type="primary" href="/register">点此注册</el-link></p>
      <el-alert v-if="message" :title="message" type="error" show-icon :closable="false" />
      <el-form
        ref="ruleFormRef"
        :model="formData"
        :rules="rules"
        class="demo-ruleForm"
        label-position="top"
        size="large"
      >
        <el-form-item label="账号" prop="account">
          <el-input v-model="formData.account" placeholder="请输入你的手机号码或电子邮箱" />
        </el-form-item>
        <el-form-item prop="password">
          <template #label>
            密码
            <el-link class="pull-right" type="primary" href="/resetPassword">忘记密码?</el-link>
          </template>
          <el-input v-model="formData.password" type="password" placeholder="请输入密码" />
        </el-form-item>
        <el-button type="primary" color="#0072EF" @click="submitForm">登录</el-button>
      </el-form>
      <p style=" justify-content: center; margin: 0;">登录即代表你已阅读并同意<el-link type="primary" target="_blank" href="/userAgreement">用户服务协议</el-link></p>
  </el-dialog>
</template>

<script>
import commonValidate  from '@/assets/js/commonValidate'
import md5 from 'js-md5'
export default {
  props: {
    visible: {
      type: Boolean
    }
  },
  data() {
    return {
      rules: {
        account: [
          { validator: commonValidate.validEmpty1, message: '请输入你的手机号码或电子邮箱', trigger: 'blur' },
          { validator: commonValidate.validateEmailAndPhone, trigger: 'blur' },
        ],
        password: [
          { validator: commonValidate.validEmpty1, message: '请输入密码', trigger: 'blur' },
          { validator: commonValidate.validatePassword, trigger: 'blur' },
        ],
      },
      formData: {
        account: '',
        password: ''
      },
      message: ''
    }
  },
  methods: {
    submitForm() {
      this.$refs.ruleFormRef.validate((value) => {
        if(value) {
          this.$API.Login({
            phoneOrEmail: this.formData.account,
            password: md5(this.formData.password)
          }, 3).then(res => {
            if(res.data.code == 200) {
              this.$emit('close', true)
              this.$store.commit('login_type', 1)
              this.$message.success('操作成功')
            }else {
              this.$message.error(res.data.msg || '操作失败')
            }
          })
        }else {
          return;
        }
      })
    }
  }
}
</script>

<style lang='scss'>
  .login_modal {
    .el-dialog__body {
      padding: 0 30px 40px;

      & > h3 {
        line-height: 30px;
        font-size: 22px;
        color: #303133;
        margin-bottom: 5px;
      }

      & > p {
        font-size: 14px;
        color: #909399;
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .el-link {
          margin-left: 5px;
          color: #0072ef;
        }
      }

      .el-form-item--large .el-form-item__content {
        line-height: 50px;
      }

      .el-input--large .el-input__inner {
        height: 48px;
      }

      .el-button {
        width: 330px;
        height: 50px;
        border-radius: 4px;
        margin-top: 18px;
        margin-bottom: 20px;
      }

      .el-alert {
        margin-bottom: 20px;
        color: #EB1948;
        padding: 10px 16px;
        line-height: 20px;
      }
    }
  }
</style>